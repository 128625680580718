import { MonthOption } from "./monthOption";

const DateService = {
    getMonthList: () : MonthOption[] => 
    {
        const locales = "en";
        const format = "long";

        const year = new Date().getFullYear();
        const monthList = Array.from(Array(12).keys());
        const formatter = new Intl.DateTimeFormat(locales, { month: format });

        return monthList.map((monthIndex: number) => {
            return { 
                name: formatter.format(new Date(year, monthIndex)), 
                number: monthIndex+1 
            } as MonthOption
        });
    },

    dropdownYearList: (numberOfYearsBack :number) : number[] =>
    {
        const year = new Date().getFullYear();
        const yearsReturned = [];

        for(let n=0; n<numberOfYearsBack; ++n)
        {
            yearsReturned.push(year - n);
        }
        
        return yearsReturned;
    },

    getMonthNameByNumber : (monthNumber: number) : string | undefined => {
        return DateService.getMonthList().find(x => x.number === monthNumber)?.name
    },
    getMonthNameByDate : (dte: Date | undefined | null) : string | undefined => {
        if(dte === null || dte === undefined) return "";
        return DateService.getMonthList().find(x => x.number === (new Date(dte).getMonth() + 1))?.name
    },
    getMonthDayYearByDate : (date: Date | undefined | null) : {month: string | undefined, day: number | undefined, year: number | undefined} | undefined => {
        if(date === null || date === undefined) return undefined;

        const month = DateService.getMonthList().find(x => x.number === (new Date(date).getMonth() + 1))?.name;
        const day = new Date(date).getDate();
        const year = new Date(date).getFullYear();

        return {month: month, day: day, year: year};
    },

    getTimeSpanUntilNow(startDate: Date){
        
            const now = new Date();
            const then = new Date(startDate);
          
            // Calculate the difference in milliseconds
            const diff = now.getTime() - then.getTime();
          
            // Convert to days
            const hours = Math.floor(diff / (1000 * 60 * 60));
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            if (hours < 48) {
              return `${hours} hours`;
            } else {
              return `${days} days`;
            }
          
        return "";
    }
}

export default DateService;