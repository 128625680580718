import { JobModel } from "../tp-core-types/JobModel";
import "./JobSearchCard.scss";
import getShiftDisplay from "../utilities/getShiftDisplay";
import { getJobCardHeaderMatchType } from "../utilities/getJobCardHeaderMatchType";
import { memo, useContext, useState } from "react";
import { PloppableContext } from "../utilities/context/PloppableContextProvider";
import displayAsMoney from "../utilities/displayAsMoney";

type Props = {
    job: JobModel, 
    handleJobClick: (job: JobModel) => void, 
    selected: boolean, 
    isArchived: boolean
}

export default memo(function JobSearchCard(props: Props) {
    const isPloppable = useContext(PloppableContext).isPloppable;
    const [jobSearchPageToReturnTo] = useState(`/careers/find-a-job/${props.job.jobId}?returnUrl=${encodeURIComponent(window.location.href)}`);

    const handleTitleClick = (e: any) => {
        e.preventDefault();
    }

    return (
        <div id="JobSearchCard" onClick={() => props.handleJobClick(props.job)}>
            <div className={`job-card ${props.selected ? "selected" : "unselected"} ${props.isArchived ? "archived" : ""}`} data-testid="job-card">
            {props.job.matchTypes && 
                <div className={`preference-match ${props.job.matchTypes && getJobCardHeaderMatchType(props.job.matchTypes).className}`}>{props.job.matchTypes && getJobCardHeaderMatchType(props.job.matchTypes).displayText}</div>
            }
                <div className="top-data">
                    {isPloppable &&
                        <div>
                            <a className="bold" data-testid="profession-title-anchor-tag" href={jobSearchPageToReturnTo} onClick={handleTitleClick}>{props.job.profession} ({props.job.specialty})</a>
                        </div>
                    }

                    {!isPloppable &&
                        <div className={`bold ${props.job.matchTypes?.specialty ? 'match-highlight' : ''}`}>{`${props.job.profession} (${props.job.specialty})`}</div>
                    }                    
                    <div>{props.job.hospitalName}</div>
                    <div className={`shift-container ${props.job.matchTypes?.shiftsPerWeek ? 'match-highlight' : ''}`}>
                        <img className="shift-icon" src={getShiftDisplay(props.job.shiftCategory).icon} alt={getShiftDisplay(props.job.shiftCategory).type}></img>
                        <div>{props.job.shift}</div>
                    </div>
                </div>
                <div className="bottom-data">
                    <div className={`location-data-container ${props.job.matchTypes?.location ? 'match-highlight' : ''}`} data-testid="location-data-container">
                        <div className="bold">{props.job.hospitalCity}, {props.job.hospitalState}</div>
                        {props.job.matchTypes?.location !== 0 &&
                            <div className="miles-from-location"><b>{props.job.matchTypes?.milesFromNearestLocation} miles </b> from {props.job.matchTypes?.nearestLocation}</div>
                        }
                    </div>
                    <div className="pay-style bold">{displayAsMoney(props.job.grossWeeklyPay)}</div>
                </div>
            </div>
        </div>
    );
})