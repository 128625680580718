import getShiftDisplay from "../../../utilities/getShiftDisplay";
import calendar_icon from "../../../assets/calendar_icon.png";
import people_icon from "../../../assets/people_icon_transparent.png";
import dollar_icon from "../../../assets/dollar_icon.png";
import clock_icon from "../../../assets/clock_icon.png";
import hospital_icon from "../../../assets/hospital_icon.png";
import { SyntheticEvent, useEffect, useState } from "react";
import "./DashboardSubmission.scss"
import checkmark_icon from "../../../assets/checkmark_large_white.png";
import clock_icon_white from "../../../assets/clock_icon_white.png";
import { JobModel } from "../../../tp-core-types/JobModel";
import { DashboardJobSubmission } from "../../../tp-core-types/DashboardJobSubmission";
import displayAsDate from "../../../utilities/displayAsDate";
import displayAsMoney from "../../../utilities/displayAsMoney";
import { useNavigate } from "react-router-dom";


type Props = {
    dashboardSubmission: DashboardJobSubmission
}

function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
event.currentTarget.src =
    "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
}
export default function DashboardSubmission(props:Props) {
    const navigate = useNavigate();
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    
    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    const handleNavigateToDetails = async () => { 
        navigate(`/submitted-jobs#job-${props.dashboardSubmission.jobId}`, {state: {jobId: props.dashboardSubmission.jobId, submissionId: null} })
    }

    return (
        <div id="DashboardSubmission">
        {!isMobile && 
          <div id="dashboardSubmission-desktop" data-testid="dashboard-submission-desktop">
             <div className="dashboard-submission-header">
                <div className="dashboard-submission-offer-info">                    
                    <div>TRS has submitted your application to the facility.</div>
                </div>
            </div>
            <div className="dashboard-submission-body">
                <div className="image-container">
                    <img
                        className="facility-image"
                        src={props.dashboardSubmission.facility.imageUrl}
                        onError={addDefaultSrc}
                        alt="facility"
                        data-testid="facility-image"
                    />
                </div>
                <div className="offer-info">
                    <div className="offer-info-header"> {`${props.dashboardSubmission.profession} (${props.dashboardSubmission.specialty}) - ${props.dashboardSubmission.facility.city}, ${props.dashboardSubmission.facility.state} `}</div>
                    <div className="job-number">Job Number: {[props.dashboardSubmission.jobId]}</div>
                    <div className="data-grid">
                        <div className="row">
                            <img className="detail-icon" src={hospital_icon} alt="facilityIcon" />
                            <div className="facility-info"> {props.dashboardSubmission.facility.facilityName} </div>
                        </div>
                        <div className="row">
                            <img className="dollar-icon" src={dollar_icon} alt="dollarIcon"/>
                            <div className="facility-info">{displayAsMoney(props.dashboardSubmission.grossWeeklyPay)}</div>
                        </div>
                        <div className="row">
                            <img className="detail-icon"
                                src={getShiftDisplay(props.dashboardSubmission.shiftCategory).icon} 
                                alt={getShiftDisplay(props.dashboardSubmission.shiftCategory).type}>
                            </img>
                            <div>{getShiftDisplay(props.dashboardSubmission.shiftCategory as number).detailText}</div>
                        </div>
                        <div className="row">
                            <img className="detail-icon" src={calendar_icon} alt="calendarIcon" />
                            <div> {props.dashboardSubmission.duration} Weeks. Starts {displayAsDate(props.dashboardSubmission.startDate)}* </div>
                        </div>
                        <div className="row">
                            <img className="detail-icon" src={clock_icon} alt="clockIcon" />
                            <div> {props.dashboardSubmission.shift}</div>
                        </div>
                    </div>
                    <div className="row">
                            <img className="people-icon" src={people_icon} alt="peopleIcon"/>
                            <div>{props.dashboardSubmission.availablePositions} Opening{props.dashboardSubmission.availablePositions > 1 ? "s" : ""}</div>
                        </div>
                    <div className="button-row">
                        <button onClick={handleNavigateToDetails} className="view-details-button">View Detailed Status</button>
                    </div>
                </div>
            </div>              
          </div>
        }

        {isMobile && 
          <div id="dashboardSubmission-mobile" data-testid="dashboard-submission-mobile" onClick={handleNavigateToDetails}>
            <div className="offer-info">
                <div className="offer-top">
                    <div className="offer-info-title">{props.dashboardSubmission.profession} ({props.dashboardSubmission.specialty})</div>
                    <div className="offer-info-facility">{props.dashboardSubmission.facility.facilityName}</div>
                    <div className="offer-info-shift">
                        <img className="detail-icon"
                            src={getShiftDisplay(props.dashboardSubmission.shiftCategory).icon} 
                            alt={getShiftDisplay(props.dashboardSubmission.shiftCategory).type}>
                        </img>                
                        <div className="offer-info-shift-display-name">{props.dashboardSubmission.shift}</div>
                    </div>
                </div>
                <div className="offer-info-location">{props.dashboardSubmission.facility.city}, {props.dashboardSubmission.facility.state}</div>
            </div>
          </div>
          
        }

        </div>
        );
}