import { JobOfferDetailsResult } from "../../tp-core-types/JobOfferDetailsResult";
import "./JobOfferBenefitInfoDetails.css";

type Props = {
    jobDetails: JobOfferDetailsResult,
};

export default function JobSearchBenefitInfoDetails (props: Props) {    
    return(
        <div data-testid="benefits-info-container">
            <div data-testid="header" className={"header bold"}>Benefits of a Travel Assignment with TRS Healthcare</div>
            <ul className="benefitsList">
                {props.jobDetails.trsBenefitsList.map((benefit: string, index: number) => <li key={index} className="benefitsListItem">{benefit}</li>)}
            </ul>
        </div>
    );
}