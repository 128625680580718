import "./Contract.scss"

type Props = {
    pdfContents: string
}

export default function Contract(props: Props) {
    
    return (
        <div className="contract-container">
            <embed title="pdf" src={props.pdfContents}></embed>
        </div>
    );
}