import { SyntheticEvent, useContext, useEffect, useRef, useState } from "react";
import "./JobOfferDetails.scss";
import { JobOfferDetailsResult } from "../tp-core-types/JobOfferDetailsResult";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import LoadingOverlay from "../loading-overlay/LoadingOverlay";
import SubmittedJobDetails from "../submitted-job-details/SubmittedJobDetails";
import displayAsDate from "../utilities/displayAsDate";
import getShiftDisplay from "../utilities/getShiftDisplay";
import { PloppableContext } from "../utilities/context/PloppableContextProvider";
import calendar_icon from "../assets/calendar_icon.png";
import clock_icon from "../assets/clock_icon.png";
import hospital_icon from "../assets/hospital_icon.png";
import left_chevron_icon from "../assets/left_chevron_icon.svg";
import JobOfferFacilityDetails from "./job-offer-facility-details/JobOfferFacilityDetails";
import { SubmittedJobDetail } from "../tp-core-types/SubmittedJobDetail";
import JobOfferMinimumRequirementsDetails from "./job-offer-minimumrequirement-details/JobOfferMinimumRequirementsDetails";
import JobOfferCityInfoDetails from "./job-offer-city-info-details/JobOfferCityInfoDetails";
import JobOfferBenefitInfoDetails from "./job-offer-benefit-details/JobOfferBenefitInfoDetails";
import { JobOfferContractDetailsResult } from "../tp-core-types/JobOfferContractDetailsResult";
import { useNavigate } from "react-router-dom";
import JobAltMainHeader from "../job-alt-main-header/JobAltMainHeader";
import OfferAltMainHeader from "../job-alt-main-header/OfferAltMainHeader";

type Props = {
    jobSubmissionId: number | null,
    jobContractId: number | null
    returnToResultsCallback?: () => void;
    submittedJobDetails: SubmittedJobDetail | null;
};

export default function JobOfferDetails(props: Props) {
    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    const [jobDetails, setJobDetails] = useState<JobOfferDetailsResult | JobOfferContractDetailsResult>();
    const [isSignableContract, setIsSignableContract] = useState<boolean>(false);
    const isPloppable = useContext(PloppableContext).isPloppable;
    const navigate = useNavigate();
    
    useEffect(()=> {
      if (props.jobContractId !== null) {
        TPCoreAPI.getJobOfferContractDetails(props.jobContractId).then((returnValue) => {
          if(returnValue && "contractId" in returnValue && "isSignable" in returnValue && returnValue.isSignable && returnValue.contractId) {
            setIsSignableContract(true);
          }
          setJobDetails(returnValue);
        });
      } 
      else if(props.jobSubmissionId !== null) {
        TPCoreAPI.getJobOfferDetails(props.jobSubmissionId).then((returnValue) => {
          
          setJobDetails(returnValue);
      });
      }
       
    }, [props.jobSubmissionId, props.jobContractId]);

  const containerRef = useRef<HTMLDivElement>(null)
  const topDivRef = useRef<HTMLDivElement>(null)
  const altDivRef = useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (containerRef.current && topDivRef.current && altDivRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top
      const topDivBottom = topDivRef.current.getBoundingClientRect().bottom
      if(topDivBottom <= containerTop){
        showAltDiv(altDivRef.current);
      }else {
        hideAltDiv(altDivRef.current);
      }
    }
  };

  const showAltDiv = (altDiv: HTMLDivElement) => {
    altDiv.style.top = '0px';
  } ;

  const hideAltDiv = (altDiv : HTMLDivElement) => {
    const calculated_top = -1*Number(altDiv.getBoundingClientRect().height);
    altDiv.style.top = `${calculated_top}px`;
  }

      
    function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
        event.currentTarget.src =
        "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
    }

    function handleSignContract()
    {
        const contractId = jobDetails && "contractId" in jobDetails ? jobDetails.contractId : -1;
        navigate("/review-and-sign-contract", {state: {contractId: contractId}});
    }

    return (
        <div id="JobOfferDetails">
           {jobDetails && (
        <div className="right-pane-with-secondary-header" data-testid="job-details">
          <div ref={containerRef} onScroll={handleScroll} className={"jobDetailListStyle"} data-testid="job-details-list-style">
            {!isPloppable && props.returnToResultsCallback && 
              <div className="mobile-navigation-bar">
                <span
                  className="returnToResults"
                  data-testid="return-to-results"
                  onClick={props.returnToResultsCallback}
                >
                  <img src={left_chevron_icon} alt="return to results"></img>
                  {"Results"}
                </span>
              </div>
            }
            <div className="jobMainHeader">
              <span className="jobMainHeaderTitle">{`${jobDetails?.discipline} (${jobDetails?.specialty})`}</span>
              <span className="jobMainHeaderSeparator">{` - `}</span>
              <span className="jobMainHeaderSubtitle">{`${jobDetails?.city}, ${jobDetails?.state}`}</span>
            </div>

            <div className="jobMainHeaderJobNumber">
              <span className="jobMainHeaderJobNumberText">{`Job Number: ${jobDetails?.jobId}`}</span>
            </div>
            <div className="rightPaneItem">
              <div className="facilityImageContainer">
                <img
                  className="facilityImage"
                  src={jobDetails?.imageUrl}
                  onError={addDefaultSrc}
                  alt="facility"
                  data-testid="facility-image"
                />
              </div>
              <div className="rightPaneDetails">
                <div className="detail-title">
                  <img className="detail-icon" src={hospital_icon} alt="Facility"></img>
                  <h3 className="detail-title-text">
                    {jobDetails?.facilityName}
                  </h3>
                </div>
                <div className="detail-item">
                  <img
                    className="detail-icon"
                    src={getShiftDisplay(jobDetails?.shiftCategory as number).icon}
                    alt="Day/Night"
                  ></img>
                  <p className="detail-item-text">
                    {getShiftDisplay(jobDetails?.shiftCategory as number).detailText}
                  </p>
                </div>
                <div className="detail-item">
                  <img className="detail-icon" src={clock_icon} alt="Shift"></img>
                  <p className="detail-item-text">{jobDetails?.shiftName} per week</p>
                </div>
                <div className="detail-item-with-helper-text">
                  <div className="detail-item">
                    <img
                      className="detail-icon"
                      src={calendar_icon}
                      alt="Starts"
                    ></img>
                    <p className="detail-item-text">
                      {jobDetails.duration} Weeks. Starts{" "}
                      {displayAsDate(jobDetails.startDate)}*
                    </p>
                  </div>
                  <p className="helper-text">
                    * In some cases, start dates can be flexible
                  </p>
                </div>
              </div>
              </div>      
                <div ref={topDivRef} className="buttonRow" data-testid="button-row">
                </div>
              <div ref={altDivRef} className="job-alt-main-header" data-testid="job-alt-main-header-container">
                  <OfferAltMainHeader jobOffer={jobDetails} signContract={handleSignContract}/>
              </div>
              {
              isSignableContract && isMobile &&
                <div className="sign-contract-button-container" data-testid="sign-contract-button-container">
                  <button data-testid="sign-contract-button-main" className="sign-contract-button" onClick={() => handleSignContract()}>Review & Sign Contract</button>
                </div>
              }
              {props.submittedJobDetails && 
                <SubmittedJobDetails isOffer={true} submittedJobDetail={props.submittedJobDetails} />
              }
              {
                isSignableContract && !isMobile &&
                  <div className="sign-contract-button-container">
                    <button className="sign-contract-button" onClick={() => handleSignContract()}>Review & Sign Contract</button>
                  </div>
              }
            
            <JobOfferFacilityDetails jobDetails={jobDetails}/>
            <JobOfferMinimumRequirementsDetails jobDetails={jobDetails}/>
            <JobOfferCityInfoDetails  jobDetails={jobDetails} />
            <JobOfferBenefitInfoDetails jobDetails={jobDetails}/>
            <div data-testid="about-trs-info-container">
                <div data-testid="header" className={"header bold"}>About TRS Healthcare</div>
                <p className="aboutTrsText">We empower healthcare professionals to achieve their personal and professional goals.
                We're committed to supporting you through every step of your travel healthcare experience.
                Our team is dedicated to caring for you so you can do the important work of caring for others.
                At TRS Healthcare, our healthcare professionals come first.</p>
            </div>
          </div>
        </div>
      )}
            { !jobDetails && <LoadingOverlay/>}
        </div>
    )
}
function showAltDiv(current: HTMLDivElement) {
    return;
}

function hideAltDiv(current: HTMLDivElement) {
    return;
}

