import "./JobOfferFacilityDetails.css";
import scrubColorIcon from "../../assets/scrub_color_icon.png";
import bedCountIcon from "../../assets/bed_count_icon.png";
import chartingSystemIcon from "../../assets/charting_system_icon.png";
import erIcon from "../../assets/er_icon.png";
import locationIcon from "../../assets/location_icon.png";
import { SyntheticEvent } from "react";
import { JobOfferDetailsResult } from "../../tp-core-types/JobOfferDetailsResult";
import MapWrapper from "../../map/MapWrapper";
import displayZip from "../../utilities/displayZip";

type Props = {
  jobDetails: JobOfferDetailsResult;
};

export default function JobOfferFacilityDetails(props: Props) {
  function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
      event.currentTarget.src =
        "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
  }

  return (
    <div data-testid="facility-details-container">
      <div data-testid="header" className={"header bold"}>
        Facility: {props.jobDetails.facilityName}
      </div>
      <div className="facilityImageContainerMobile">
        <img
          className="facilityImageMobile"
          src={props.jobDetails.imageUrl}
          onError={addDefaultSrc}
          alt="facility"
          data-testid="mobile-facility-image"
        />
      </div>
      <div data-testid="info" className="facilityInfo">
        <div data-testid="scrubColor" className="facilityInfoItem">
          <img
            className="facility-detail-icon"
            src={scrubColorIcon}
            alt=""
          ></img>
          Scrub Color: <span className="bold">{createScrubColorString()}</span>
        </div>
        <div data-testid="bedCount" className="facilityInfoItem">
          <img className="facility-detail-icon" src={bedCountIcon} alt=""></img>
          Bed Count: <span className="bold">{createBedCountString()}</span>
        </div>
        <div data-testid="charting" className="facilityInfoItem">
          <img
            className="facility-detail-icon"
            src={chartingSystemIcon}
            alt=""
          ></img>
          Charting System:{" "}
          <span className="bold">{createChartingString()}</span>
        </div>
        <div data-testid="trauma" className="facilityInfoItem">
          <img className="facility-detail-icon" src={erIcon} alt=""></img>
          Trauma Center:{" "}
          <span data-testid="traumalevel" className="bold">
            {createTraumaLevelString()}
          </span>
        </div>
      </div>
      <div className="facilityWrapper">
        <div data-testid="location" className="facilityInfo">
          <div data-testid="address" className="facilityInfoItem bold">
            <img
              className="facility-detail-icon"
              src={locationIcon}
              alt=""
            ></img>
            {createAddressString()}
          </div>
        </div>
        <div className="mapContainerBorder">
          <div className="mapContainer" data-testid="map-container">
            <MapWrapper address={createAddressString()} />
          </div>
        </div>
      </div>
    </div>
  );

  function createAddressString() {
    let addressString = "";

    if (props.jobDetails.address1) {
      addressString += props.jobDetails.address1;
    }

    if (props.jobDetails.address2) {
      addressString += " " + props.jobDetails.address2;
    }

    if (
      props.jobDetails.address1 ||
      props.jobDetails.address2
    ) {
      addressString += ", ";
    }

    addressString +=
      props.jobDetails.city + ", " + props.jobDetails.state;

    if (props.jobDetails.zip) {
      addressString += " " + displayZip(props.jobDetails.zip);
    }
    return addressString;
  }

  function createTraumaLevelString() {
    if (
      props.jobDetails.traumaLevel &&
      props.jobDetails.traumaLevel !== "null"
    ) {
      return "Level " + props.jobDetails.traumaLevel;
    }
    return "Not Available";
  }

  function createBedCountString() {
    if (props.jobDetails.bedCount) {
      if (props.jobDetails.bedCount > 1) {
        return props.jobDetails.bedCount + " Beds";
      } else {
        return props.jobDetails.bedCount + " Bed"
      }
    }
    return "Not Available";
  }

  function createChartingString() {
    if ( props.jobDetails.chartingSystems && props.jobDetails.chartingSystems.length > 0) {
      return props.jobDetails.chartingSystems.join(", ");
    }
    return "Not Available";
  }

  function createScrubColorString() {
    if (props.jobDetails.scrubColor) {
      return props.jobDetails.scrubColor
    }
    return "Not Available";
  }
}
