import { DocumentResult } from "../tp-core-types/DocumentResult";
import { Session } from "../utilities/Session";

const TPCoreAPICommon = {
    getDocument: async (documentName: string) => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "GET",
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/SubmitApplication/getSubmitApplicationDocuments/${documentName}`,
            options
        );

        return response.ok ? (response.blob()) : Promise.reject({});
    },

    eSignDocument: async(contractId: number, documentMasterId: number): Promise<Response> => {
        const hcpLoginToken = Session.getHcpLoginToken();
        const options = {
            method: "POST",
            body: JSON.stringify({}),
            headers: {
                Authorization: `Bearer ${hcpLoginToken}`,
                "Content-Type": "application/json"
            }
        }
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/Document/eSignDocument?contractId=${contractId}&documentMasterId=${documentMasterId}`,
            options
        );

        return response.ok ? response : Promise.reject({});
    },
}

export default TPCoreAPICommon;