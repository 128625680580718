import { JobOfferResultItem } from "../tp-core-types/JobOffersResult";
import getShiftDisplay from "../utilities/getShiftDisplay";
import "./JobOfferCard.scss";
import clock  from "../../src/assets/clock_icon_white.png";
import DateService from "../utilities/dateService";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

type Props = {
    jobOfferResultItem: JobOfferResultItem
    handleJobOfferClick: (jobOffer: JobOfferResultItem) => void, 
    selected: boolean
}

export default function JobOfferCard(props: Props) {
    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    const isSignable = props.jobOfferResultItem.contractId && props.jobOfferResultItem.isSignable;
    const title = isSignable ? "Your contract is ready to sign!" : "You received an offer!"
    const navigate = useNavigate();

    

    function handleClick()
    {
        navigate("/review-and-sign-contract", {state: {contractId: props.jobOfferResultItem.contractId}});
    }

    return (
        <>
            <div id="JobOffersCard" onClick={() => props.handleJobOfferClick(props.jobOfferResultItem)}>
                <div className={`job-card ${props.selected ? "selected" : "unselected"}`} data-testid="job-card">
                {!isMobile && <div className="offer-title">{title}</div> }
                    <div className="top-data">
                        <div className={`bold `}>{`${props.jobOfferResultItem.profession} (${props.jobOfferResultItem.specialty})`}</div>
                        <div>{props.jobOfferResultItem.hospitalName}</div>
                        <div className={`shift-container `}>
                            <img className="shift-icon" src={getShiftDisplay(props.jobOfferResultItem.shiftCategory).icon} alt={getShiftDisplay(props.jobOfferResultItem.shiftCategory).type}></img>
                            <div>{props.jobOfferResultItem.shift}</div>
                        </div>
                    </div>
                    <div className="bottom-data">
                        <div className={`location-data-container `} data-testid="location-data-container">
                            <div className="bold">{props.jobOfferResultItem.city}, {props.jobOfferResultItem.state}</div>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile && isSignable && 
            <div id="SignContractButton" className="sign-contract-button-container">
                <button className="sign-contract-button" onClick={() => handleClick()}>Review & Sign Contract</button>
            </div>
             }
        </>
    );
}

