import { JobOfferContractDetailsResult } from "../tp-core-types/JobOfferContractDetailsResult";
import { JobOfferDetailsResult } from "../tp-core-types/JobOfferDetailsResult";
import "./JobAltMainHeader.css";

type Props = {
  jobOffer: JobOfferDetailsResult | JobOfferContractDetailsResult,
  signContract: () => void
}

export default function OfferAltMainHeader(props: Props) {
    const isSignableContract = "contractId" in props.jobOffer && "isSignable" in props.jobOffer && props.jobOffer.contractId !== null && props.jobOffer.isSignable;

    return (
        <div data-testid='job-alt-main-header'>
          <div className="left-justified">
            <div className="job-alt-header-title">{props.jobOffer.discipline} ({props.jobOffer.specialty}) - {props.jobOffer.city},{props.jobOffer.state}</div>
            <div className="job-alt-detail-item">Job Number: {props.jobOffer.jobId}</div>
            <div className="job-alt-detail-item">{props.jobOffer.facilityName}</div>
          </div>
          <div className="right-justified">
            {
                isSignableContract &&
                <div className="sign-contract-button-container">
                <button className="sign-contract-button" onClick={() => props.signContract()}>Review & Sign Contract</button>
                </div>
            }
          </div>
        </div>
    );
}

