import { DashboardJobOffer } from "../tp-core-types/DashboardJobOffer";
import { DashboardSubmissions } from "../tp-core-types/DashboardJobSubmission";
import { Session } from "../utilities/Session";

const TPCoreAPIDashboard = {
    getDashboardJobOffers: async (): Promise<DashboardJobOffer[]> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobOffers/getHcpDashboardOffers`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    },

    getDashboardJobSubmissions: async (): Promise<DashboardSubmissions> => {
        const token = Session.getHcpLoginToken();
        const options = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        };
        const response = await fetch(
            `${process.env.REACT_APP_TRAVEL_PRO_CORE_BASE_URL}/HcpJobSubmissions/getHcpDashboardSubmissions`,
            options
        );

        return response.ok ? await response.json() : Promise.reject({});
    }
}

export default TPCoreAPIDashboard;