import { MouseEvent, useEffect, useState } from "react";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import Contract from "./Contract";
import TPCoreAPICommon from "../tp-core-api-common/TPCoreAPICommon";
import { useError } from "../utilities/context/useError";
import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { useLocation, useNavigate } from "react-router-dom";
import "./SignContract.scss"
import LoadingOverlay from "../loading-overlay/LoadingOverlay";


export default function SignContract() {
    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    const HCPA_DOCUMENTMASTERID = 54;
    const navigate = useNavigate();
    const {setError} = useError();
    const location = useLocation();
    const [contractId, setContractId] = useState<number | undefined>()
    const [loading, setLoading] = useState<boolean>(true);
    const [file, setFile] = useState<string>("")
    const [images, setImages] = useState<string[]>([]);
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    async function getFile() {
        if(location && location.state && location.state.contractId) {
            setContractId(location.state.contractId);
            if(!isMobile) {
                TPCoreAPI.getHCPA(location.state.contractId).then((res) => {
                    setLoading(false);
                    const file = URL.createObjectURL(res)
                    return file;
                    }).then((pdf) => {
                        setFile(pdf)
                    }).catch(()=>{
                        setError(new Error("It looks like something went wrong. Please try again."));
                        navigate("/dashboard")
                        });
            }
            else {
                TPCoreAPI.getHCPAImages(location.state.contractId).then((res: string[]) => {
                    setLoading(false);
                    setImages(res);
                }).catch(()=>{
                    setError(new Error("It looks like something went wrong. Please try again."));
                    navigate("/dashboard")
                    });
            }
        }
        else{
            navigate("/dashboard");
        }
    }

    useEffect(() => {
        getFile();
    }, [])

    async function handleSubmit(e:MouseEvent<HTMLButtonElement, unknown>) {
        setButtonDisabled(true);
        setLoading(true);
            if(contractId) {
                await TPCoreAPICommon.eSignDocument(contractId, HCPA_DOCUMENTMASTERID).then(() => {
                    navigate("/dashboard")
                })
                .catch(() => {
                    setError(new Error("It looks like something went wrong. Please try again."))
                    navigate("/dashboard")
                });
            } 
            else {
                setError(new Error("It looks like something went wrong. Please try again."))
                navigate("/dashboard")
            }
    }

    return (
        <div id="ReviewAndSignContract">
            <GenericPageHeader title="Review & Sign Contract"></GenericPageHeader>
            {loading === true ? <LoadingOverlay/> :
            <div className="page-container" >
                <div className="button-container">
                    <div className="checkbox-header-container">
                        <div className="checkbox-container">
                        <input type="checkbox" data-testid="agree-checkbox" id="agree-checkbox" className="agree-checkbox checkbox" onChange={() => setButtonDisabled(!buttonDisabled)}></input>
                            <label htmlFor="agree-checkbox">
                                I have reviewed and agree to sign electronically.
                            </label>
                        </div>
                    </div>
                    <button className="sign-contract-button" disabled={buttonDisabled} onClick={e => handleSubmit(e)}>Sign Contract</button>
                    {isMobile && <hr/>}
                </div>
                { file !== "" && !isMobile && <Contract pdfContents={file}/> }
                {
                    (images.length > 0 && isMobile) &&
                        <>
                            <div className="images-container" data-testid="mobile-contract-view">
                                { images.map((i) => {
                                    return <img alt="contractImage" src={i}></img>
                                })}
                            </div>
                            <div className="footer"/>
                        </>
                }
            </div>
        }
        </div>
    );
}


