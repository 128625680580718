import { JobOfferDetailsResult } from "../../tp-core-types/JobOfferDetailsResult";
import "./JobOfferMinimumRequirementsDetails.css";

type Props = {
    jobDetails: JobOfferDetailsResult;
};

export default function JobOfferMinimumRequirementsDetails(props: Props) {    
    return(
        <div data-testid="minimum-requirements-container">
            <div data-testid="header" className={"header bold"}>Minimum Requirements</div>
            <ul className="requirementsList">
                {props.jobDetails.minimumRequirements.map((requirement, index) => <li key={index} className="requirementListItem">{requirement}</li>)}
            </ul>
        </div>
    );
}


