import { DashboardJobOffer } from "../../../tp-core-types/DashboardJobOffer";
import getShiftDisplay from "../../../utilities/getShiftDisplay";
import calendar_icon from "../../../assets/calendar_icon.png";
import clock_icon from "../../../assets/clock_icon.png";
import hospital_icon from "../../../assets/hospital_icon.png";
import { SyntheticEvent, useEffect, useState } from "react";
import "./DashboardOffer.scss"
import checkmark_icon from "../../../assets/checkmark_large_white.png";
import displayAsDate from "../../../utilities/displayAsDate";
import { useNavigate } from "react-router-dom";


type Props = {
    dashboardOffer: DashboardJobOffer
}

function addDefaultSrc(event: SyntheticEvent<HTMLImageElement, Event>) {
event.currentTarget.src =
    "https://apptest.trshealthcare.com/api/avatar/avatarbyid/933923/0/jpg";
}
export default function DashboardOffer(props:Props) {
    const isSignableContract = props.dashboardOffer.contractId && props.dashboardOffer.isSignable;
    const navigate = useNavigate();
    const getIsMobile = (): boolean => {
        return window.matchMedia("(max-width: 1250px)").matches;
    }
    
    const [isMobile, setIsMobile] = useState(getIsMobile());
    
    const handleGetContract = async () => {
        navigate("/review-and-sign-contract", {state: {contractId: props.dashboardOffer.contractId}});
    }

    const handleNavigateToDetails = async () => {
            let selectedId;
            if(props.dashboardOffer.contractId) {
                selectedId = props.dashboardOffer.contractId;
                navigate(`/submitted-jobs#contract-${selectedId}`, {state: { jobId: null, submissionId: null, contractId: selectedId } });
            }
            else if (props.dashboardOffer.submissionId) {
                selectedId = props.dashboardOffer.submissionId;
                navigate(`/submitted-jobs#offer-${selectedId}`, {state: { jobId: null, submissionId: selectedId, contractId: null } }); 
            }
            else {
                navigate('/submitted-jobs')
            }
    }
    

    useEffect(()=>{
        const evtListener = ()=>{
            const mobile = getIsMobile();
            setIsMobile(mobile);
        }
        window.addEventListener('resize', evtListener);

        //clean up after the component umounts.
        return () => {
            window.removeEventListener('resize',evtListener);
        }
    },[])

    return (
        <div className="DashboardOffer">
        {!isMobile && 
          <div className="dashboardSubmission-desktop" data-testid="dashboard-offer-desktop">
             <div className="dashboard-submission-header">
                <div className="dashboard-submission-offer-info">                    
                    <img src={checkmark_icon} alt="Check"  className="dashboard-submission-offer-chkmrk"></img>
                    <div>{isSignableContract ? "Your contract is ready to sign!" : "You received an offer!"}</div>    
                </div>
                
            </div>
            <div className="dashboard-submission-body">
                <div className="image-container">
                    <img
                        className="facility-image"
                        src={props.dashboardOffer.facility.imageUrl}
                        onError={addDefaultSrc}
                        alt="facility"
                        data-testid="facility-image"
                    />
                </div>
                <div className="offer-info">
                    <div className="offer-info-header"> {`${props.dashboardOffer.profession} (${props.dashboardOffer.specialty}) - ${props.dashboardOffer.facility.city}, ${props.dashboardOffer.facility.state} `}</div>
                    <div className="job-number">Job Number: {[props.dashboardOffer.jobId]}</div>
                    <div className="data-grid">
                        <div className="row">
                            <img className="detail-icon" src={hospital_icon} alt="facilityIcon" />
                            <div className="facility-info"> {props.dashboardOffer.facility.facilityName} </div>
                        </div>
                        <div className="row">
                            <img className="detail-icon" src={clock_icon} alt="clockIcon" />
                            <div> {props.dashboardOffer.shift}</div>
                        </div>
                         <div className="row">
                            <img className="detail-icon"
                                src={getShiftDisplay(props.dashboardOffer.shiftCategory).icon} 
                                alt={getShiftDisplay(props.dashboardOffer.shiftCategory).type}>
                            </img>
                            <div>{getShiftDisplay(props.dashboardOffer.shiftCategory as number).detailText}</div>
                        </div>
                       
                        <div className="row">
                            <img className="detail-icon" src={calendar_icon} alt="calendarIcon" />
                            <div> {props.dashboardOffer.duration} Weeks. Starts {displayAsDate(props.dashboardOffer.startDate)}* </div>
                        </div>
                    </div>
                    <div className="button-row">
                        <button onClick={() => handleNavigateToDetails()} className="view-details-button">View Detailed Status</button>
                        {isSignableContract &&
                            <button onClick={() => handleGetContract()} className="sign-contract-button">Review & Sign Contract</button>
                        }
                    </div>
                </div>
            </div>              
          </div>
        }

        {isMobile && 
        <>
          <div id="dashboardSubmission-mobile" data-testid="dashboard-offer-mobile" onClick={() => handleNavigateToDetails()}>
            <div className="offer-info">
                <div className="offer-top">
                    <div className="offer-info-title">{props.dashboardOffer.profession} ({props.dashboardOffer.specialty})</div>
                    <div className="offer-info-facility">{props.dashboardOffer.facility.facilityName}</div>
                    <div className="offer-info-shift">
                        <img className="detail-icon"
                            src={getShiftDisplay(props.dashboardOffer.shiftCategory).icon} 
                            alt={getShiftDisplay(props.dashboardOffer.shiftCategory).type}>
                        </img>                
                        <div className="offer-info-shift-display-name">{props.dashboardOffer.shift}</div>
                    </div>
                </div>
                <div className="offer-info-location">{props.dashboardOffer.facility.city}, {props.dashboardOffer.facility.state}</div>
            </div>
          </div>
            {props.dashboardOffer.contractId && props.dashboardOffer.isSignable && 
            <div id="SignContractButton" className="sign-contract-button-container">
                <button className="sign-contract-button" onClick={() => handleGetContract()}>Review & Sign Contract</button>
            </div>
             }
        </>
        }
        </div>
        );
    }