import { useNavigate } from "react-router-dom";
import { Session } from "../utilities/Session";
import "./UserMenuModal.css"
import { useContext } from "react";
import FeatureFlagContext from "../tp-core-types/FeatureFlagsModel";

type Props = {
    setShowUserMenuModal: (isVisible: boolean) => void;
    applicationComplete: boolean;
    showStartApplicationLink?: boolean;
}

export default function UserMenuModal(props:Props) {
    const ff = useContext(FeatureFlagContext);

    const isMobile = window.matchMedia("(max-width: 800px)").matches;

    const navigate = useNavigate();
    const logout = () => {
        Session.clearHcpLoginToken();    
        window.location.href = process.env.REACT_APP_HOMEPAGE_URL!;
    }

  
    return(
        <>
            <div className='user-menu-modal-background' data-testid='user-menu-modal-background' onClick={()=>props.setShowUserMenuModal(false)}>
                <div data-testid='user-menu-items-container' className='user-menu-items-container' onClick={(e)=>e.stopPropagation()}>
                    {isMobile && (props.applicationComplete) &&
                        <a className='single-link-container' data-testid='go-to-travelerhub' href={process.env.REACT_APP_TRAVELER_HUB_URL}>Go to Traveler Hub</a>
                    }
                    <div className='single-link-container' data-testid='go-to-dashboard' onClick={(e)=>{e.stopPropagation(); props.setShowUserMenuModal(false); navigate('/dashboard')}}>Dashboard</div>
                    <div className='single-link-container' data-testid='go-to-favorites' onClick={(e)=>{e.stopPropagation(); props.setShowUserMenuModal(false); navigate('/favorite-jobs')}}>Jobs Added to Favorites</div>
                    <div className='single-link-container' data-testid='go-to-submitted-jobs' onClick={(e)=>{e.stopPropagation(); props.setShowUserMenuModal(false); navigate('/submitted-jobs')}}>{ff.featureFlag.featureFlag_DashboardOffersAndSubmissions_IsEnabled ? "Submissions & Offers": "Current Submissions"}</div>
                    { props.showStartApplicationLink && 
                        <div className='single-link-container' data-testid='go-to-apply-now' onClick={(e)=>{e.stopPropagation(); props.setShowUserMenuModal(false); navigate('/apply-now')}}>Start TRS Application</div>
                    }
                    { props.applicationComplete &&
                        <div className='single-link-container' data-testid='profile-page-link' onClick={(e)=>{e.stopPropagation(); props.setShowUserMenuModal(false); navigate('/profile-page')}}>Profile</div>
                    }
                    <div className='single-link-container' data-testid='user-menu-logout' onClick={logout}>Logout</div>
                </div>
            </div>
        </>
    )
}