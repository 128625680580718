import { ReactNode, useEffect, useState } from "react";
import TPCoreAPI from "../../tp-core-api/TPCoreAPI";
import FeatureFlagContext, { FeatureFlagsModel } from "../../tp-core-types/FeatureFlagsModel";

export function FeatureFlagContextProvider({ children }: { children: ReactNode }) {
    const defaultFeatureFlag = {
        featureFlag: {
            featureFlag_HcpApplication_IsEnabled: false,
            featureFlag_HcpApplication_SkillsChecklist_IsEnabled: false,
            featureFlag_DashboardOffersAndSubmissions_IsEnabled: false,
        },
    };
    const [featureFlag, setFeatureFlag] = useState<FeatureFlagsModel>(defaultFeatureFlag);

    useEffect(() => {
        TPCoreAPI.getFeatureFlags()
            .then((flags: FeatureFlagsModel) => {
                setFeatureFlag(flags);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);
    return <FeatureFlagContext.Provider value={featureFlag}>{children}</FeatureFlagContext.Provider>;
}
