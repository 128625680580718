import TPCoreAPI from "../tp-core-api/TPCoreAPI";
import { useState, useEffect, useContext } from 'react';
import { FavoriteJobResultsModel } from "../tp-core-types/FavoriteJobResultsModel";
import JobClosedHeader from "../job-closed-header/JobClosedHeader";
import "./FavoriteJobResults.scss"
import JobSearchCard from "../job-search-card/JobSearchCard";
import { JobModel } from "../tp-core-types/JobModel";
import JobSearchDetails from "../job-search-details/JobSearchDetails";
import LoginModal from "../login-modal/LoginModal";
import { Session } from "../utilities/Session";
import UserInfoModel from "../tp-core-types/UserInfoModel";
import GenericPageHeader from "../generic-page-header/GenericPageHeader";
import { TRSHeaderContext } from "../utilities/context/TRSHeaderContextProvider";

export default function FavoriteJobResults() {
    const userInfoModel = useContext(UserInfoModel)
    const [displayedJobResults, setDisplayedJobResults] = useState(null as JobModel[] | null);
    const [selectedJob, setSelectedJob] = useState(null as JobModel | null);
    const [isArchived, setIsArchived] = useState(false);
    const [jobs, setJobs] = useState<FavoriteJobResultsModel>();
    const [showLoginModalVisibility, setShowLoginModalVisibility] = useState(false)

    const isMobile = window.matchMedia("(max-width: 1250px)").matches;
    
    const {setIsTRSHeaderVisible} = useContext(TRSHeaderContext);

    useEffect(() => {
        userInfoModel.subscribeForLogout(handleLogout)
        if(Session.getHcpLoginToken() !== ""){
            getFavoriteJobResults();
            setShowLoginModalVisibility(false);
        } else {
            setShowLoginModalVisibility(true);
        }
        return function cleanup() {
            userInfoModel.unsubscribeForLogout(handleLogout)
        }
    }, [])
    
    const getFavoriteJobResults = async () => {
        const results = await TPCoreAPI.getFavoriteJobs()
        
        setJobs(results);
        setDisplayedJobResults(results.activeJobs);

        if (
            results.activeJobs.length > 0 &&
            !isMobile
          ) {
            setSelectedJob(results.activeJobs[0])
        }
    }

    const handleLogout = () => {
        setShowLoginModalVisibility(true);
    }

    const handleLogin = async () => {
        setShowLoginModalVisibility(false);
        getFavoriteJobResults()
    }

    const clearJobSelection = () => {
        setSelectedJob(null);
        setIsTRSHeaderVisible(true);
    }

    const handleJobClick = (job: JobModel) => {
        setSelectedJob(job)
        setIsTRSHeaderVisible(false);
    }

    const handleArchivedClick = () => {
        if(!jobs)
            return;

        setIsArchived(true);
        setDisplayedJobResults(jobs.archivedJobs);
        if (!isMobile) {
            setSelectedJob(jobs.archivedJobs[0])
        }
    }

    const handleActiveClick = () => {
        if(!jobs)
            return;

        setIsArchived(false);
        setDisplayedJobResults(jobs.activeJobs)
        if (!isMobile) {
            setSelectedJob(jobs.activeJobs[0])
        }
    }
          
    return(
        <div id="FavoriteJobResults">
            <LoginModal isLoginForm={true} isVisible={showLoginModalVisibility} setVisibility={(value: boolean) => setShowLoginModalVisibility(value)} successfulLogin={handleLogin} headerText="Your session has expired, please log in again." canBeDismissed={false}/>
            <GenericPageHeader title="Jobs Added to Favorites" />
            
            <div className="favorites-page-container" data-testid="container-style">
                <div className="button-and-jobs-container">
                    <div className="button-container">
                        <button className={!isArchived ? "selected" : "unselected"} data-testid="active-button" onClick={handleActiveClick} >
                            {jobs?.activeJobs? `Active (${jobs?.activeJobs.length})` : "Active"}
                        </button>
                        <button className={isArchived ? "selected" : "unselected"} data-testid="archived-button" onClick={handleArchivedClick}>
                            {jobs?.archivedJobs? `Archived (${jobs?.archivedJobs.length})` : "Archived"}
                        </button>
                    </div>
                    <div className="left-pane" data-testid="job-list"> 
                        <div className="job-list">
                        { displayedJobResults && displayedJobResults.map((job) => {
                            return(
                                <div>
                                    {isArchived && job.jobId === selectedJob?.jobId && <JobClosedHeader /> }
                                    {isArchived && isMobile && <JobClosedHeader /> }
                                    <div data-testid={`job${job.jobId}`} key={`job${job.jobId}`}>
                                    <JobSearchCard 
                                        job={job} 
                                        handleJobClick={handleJobClick} 
                                        selected={job.jobId === selectedJob?.jobId} 
                                        isArchived={isArchived}/>
                                    </div>
                                </div>
                            )})}                    
                        </div>
                    </div>
                </div>
                {selectedJob && (
                    <JobSearchDetails neverShowActionButtons={false} jobId={selectedJob.jobId} returnToResultsCallback={clearJobSelection} submittedJobDetails={null} />
                )}
            </div>
        </div>
    )
}