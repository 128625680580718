import { JobOfferDetailsResult } from "../../tp-core-types/JobOfferDetailsResult";
import "./JobOfferCityInfoDetails.css"

type Props = {
    jobDetails: JobOfferDetailsResult
};

export default function JobOfferCityInfoDetails (props: Props) {
    return(
        <div data-testid="city-info-container">
            <div data-testid="header" className={"header bold"}>About {props.jobDetails.city}, {props.jobDetails.state}</div>
            <div className="city-info-item" data-testid="city-info-item">
                <span className="city-info-item-label">Housing:</span>
                <a target="_blank" rel="noreferrer" className={"city-info-link"} href="https://www.furnishedfinder.com/" data-testid="city-info-link">Furnished Finder</a>
            </div>
            <div className="city-info-item" data-testid="city-info-item">
                <span className="city-info-item-label">Weather:</span>
                <a target="_blank" rel="noreferrer" className={"city-info-link"} href="https://www.weather.gov/wrh/climate" data-testid="city-info-link">National Weather Service</a>
            </div>
            <div className="city-info-item" data-testid="city-info-item">
                <span className="city-info-item-label">Cost of Living:</span>
                <a target="_blank" rel="noreferrer" className={"city-info-link"} href="https://www.bls.gov/regions/subjects/consumer-price-indexes.htm" data-testid="city-info-link">Consumer Price Index (US Bureau of Labor Statistics)</a>
            </div>
        </div>
    );
}