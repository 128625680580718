import { Routes, Route } from "react-router-dom";
import "./App.css";
import JobSearchPage from "./job-search-page/JobSearchPage";
import ExternalJobSearchForm from "./external-job-search-form/ExternalJobSearchForm";
import TRSHeader from "./trs-header/TRSHeader";
import FavoriteJobResults from "./favorite-job-results/FavoriteJobResults";
import Dashboard from "./Dashboard/Dashboard";
import LoginPage from "./login-page/LoginPage";
import SubmittedJobResults from "./submitted-job-results/SubmittedJobResults";
import { useContext, useEffect } from "react";
import { Session } from "./utilities/Session";
import UserInfoModel from "./tp-core-types/UserInfoModel";
import ResetPasswordPage from "./forgot-password-reset-page/ResetPasswordPage";
import ModalErrorMesage from "./error-message/modal-error-message/ModalErrorMessage";
import ApplyNow from "./HCPApplication/apply-now/ApplyNow";
import PermanentAddress from "./HCPApplication/permanent-address/PermanentAddress";
import FeatureFlagContext from "./tp-core-types/FeatureFlagsModel";
import BackgroundQuestions from "./HCPApplication/background-questions/BackgroundQuestions";
import LicenseInfo from "./HCPApplication/license-info/LicenseInfo";
import EducationInfo from "./HCPApplication/education-info/EducationInfo";
import EmploymentHistory from "./HCPApplication/employment-history/EmploymentHistory";
import RequiresLogin from "./login-modal/RequiresLogin";
import References from "./HCPApplication/references/References";
import Identification from "./HCPApplication/Identification/Identification";
import Certifications from "./HCPApplication/certifications/Certifications";
import TermsAndConditions from "./HCPApplication/terms-and-conditions/TermsAndConditions";
import Application from "./HCPApplication/Application";
import ProfilePage from "./HCPProfile/ProfilePage";
import SkillsChecklist from "./HCPApplication/skills-checklist/SkillsChecklist";
import SkillsChecklistSummary from "./HCPApplication/skills-checklist-summary/SkillsChecklistSummary";
import SignContract from "./sign-contract-page/SignContract";

function App() {
    const userInfoModel = useContext(UserInfoModel);
    const ff = useContext(FeatureFlagContext);
    
    const parseJwt = (token: string): { exp: number } | undefined => {
        let jsonPayload = "";
        if (token) {
            const base64Url = token.split(".")[1];
            const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            jsonPayload = decodeURIComponent(
                window
                    .atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );
            return JSON.parse(jsonPayload);
        }
        return undefined;
    };

    const checkIsUserLoggedIn = () => {
        const tokenStr = Session.getHcpLoginToken();
        const tokenObj = parseJwt(tokenStr);
        if (tokenObj) {
            const expirationWindow = 60;
            const tokenExp = tokenObj.exp;
            const curDate = Date.now() / 1000;
            const tokenExpirationWithinAMinute = tokenExp - curDate;

            if (expirationWindow >= tokenExpirationWithinAMinute) {
                Session.clearHcpLoginToken();
                userInfoModel.getAndSetToken();
            }
        }
        return;
    };

    useEffect(() => {
        setInterval(checkIsUserLoggedIn, 5000);
    }, []);

    useEffect(() => {
        checkIsUserLoggedIn();
    });

    return (
        <>
            <TRSHeader />
            <Routes>
                <Route path="/" element={<RequiresLogin title="Dashboard"><Dashboard /></RequiresLogin>}></Route>
                <Route path="/job-search" element={<JobSearchPage />}></Route>
                <Route path="/search-form" element={<ExternalJobSearchForm />}></Route>
                <Route path="/favorite-jobs" element={<FavoriteJobResults />}></Route>
                <Route path="/dashboard" element={<RequiresLogin title="Dashboard"><Dashboard /></RequiresLogin>}></Route>
                <Route path="/login-page" element={<LoginPage />}></Route>
                <Route path="/submitted-jobs" element={<SubmittedJobResults />} />
                <Route path="/reset-password" element={<ResetPasswordPage />} />                
                <Route path="/profile-page" element={<RequiresLogin title="Profile"><ProfilePage /></RequiresLogin>} />
                {ff.featureFlag.featureFlag_DashboardOffersAndSubmissions_IsEnabled &&
                    <>
                        <Route path="/review-and-sign-contract" element={<RequiresLogin title="Review & Sign Contract"><SignContract /></RequiresLogin>}></Route>
                    </>
                }
            </Routes>
        <>
            {ff.featureFlag.featureFlag_HcpApplication_IsEnabled && ( 
                <Application>
                    <Routes>
                            <Route path="/apply-now" element={<RequiresLogin title="Apply Now"><ApplyNow /></RequiresLogin>} />
                            <Route path="/background-questions" element={<RequiresLogin title="Background Questions"><BackgroundQuestions /></RequiresLogin>} />
                            <Route path="/permanent-address" element={<RequiresLogin title="Permanent Address"><PermanentAddress /></RequiresLogin>} />
                            <Route path="/license-info" element={<RequiresLogin title="Primary License Info"><LicenseInfo /></RequiresLogin>} />
                            <Route path="/education-info" element={<RequiresLogin title="Education"><EducationInfo /></RequiresLogin>} />
                            <Route path="/employment-history" element={<RequiresLogin title="Employment History"><EmploymentHistory /></RequiresLogin>} />
                            <Route path="/references" element={<RequiresLogin title="References"><References /></RequiresLogin>} />
                            <Route path="/certifications" element={<RequiresLogin title="Certifications"><Certifications></Certifications></RequiresLogin>}/>
                            <Route path="/identification-info" element={<RequiresLogin title="Identification Info"><Identification /></RequiresLogin>} />
                            <Route path="/terms-and-conditions" element={<RequiresLogin title="Terms and Conditions"><TermsAndConditions /></RequiresLogin>} />
                             {ff.featureFlag.featureFlag_HcpApplication_SkillsChecklist_IsEnabled &&
                                <>
                                    <Route path="/skills-checklist" element={<RequiresLogin title="Skills Checklist"><SkillsChecklist /></RequiresLogin>} />
                                    <Route path="/skills-checklist-summary" element={<RequiresLogin title="Skills Checklist Summary"><SkillsChecklistSummary /></RequiresLogin>} />
                                </>
                             }
                            

                    </Routes>
                </Application>
            )}
            </>
            <ModalErrorMesage />
        </>
    );
}

export default App;
